document.addEventListener('DOMContentLoaded', function() {
  // Get all images with the 'bdg-img' class
  var images = document.querySelectorAll('.bdg-img');

  // Add a click event listener to each image
  images.forEach(function(image) {
    image.addEventListener('click', function() {
      // Create a new modal element
      var modal = document.createElement('div');
      modal.classList.add('modal');

      // Create an image element inside the modal
      var modalImage = document.createElement('img');
      modalImage.src = this.src;
      modalImage.classList.add('modal-image');

      // Add the image to the modal
      modal.appendChild(modalImage);

      // Add the modal to the document body
      document.body.appendChild(modal);

      // Add a click event listener to close the modal when clicked
      modal.addEventListener('click', function() {
        document.body.removeChild(modal);
      });
    });
  });
});
